<template>
	<el-dialog title="查看人员" :visible.sync="dialogVisible" :close-on-click-modal="false" width="1080px" :before-close="handleClose">
		<el-table style="margin: 0 auto" :data="tableData" border :header-cell-style="headerCellStyle">
			<template slot="empty">
				<IsEmpty style="padding: 20px 0 !important" />
			</template>
			<el-table-column prop="headImage" label="头像" align="center">
				<template slot-scope="scope">
					<img :src="scope.row.headImage" alt="" class="img" />
				</template>
			</el-table-column>
			<el-table-column prop="name" label="姓名" align="center" />
			<el-table-column prop="sex" label="性别" align="center">
				<template slot-scope="scope">
					<span>{{ scope.row.sex === 0 ? '女' : scope.row.sex === 1 ? '男' : '--' }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="cardId" label="身份证号" align="center" />
			<el-table-column prop="serviceName" label="所属服务机构" align="center" />
			<el-table-column prop="introduce" label="简介" align="center" :show-overflow-tooltip="true" />
		</el-table>
		<el-pagination
			background
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="tableParams.pageNum"
			:page-sizes="[10, 20, 30, 40]"
			:page-size="tableParams.pageSize"
			layout="total, sizes, prev, pager, next"
			:total="total"
		>
		</el-pagination>
		<div slot="footer">
			<el-button @click="handleClose">取 消</el-button>
			<el-button type="primary" @click="handleClose">确 定</el-button>
		</div>
	</el-dialog>
</template>

<script>
import { hksStaffPage } from '@/api/organManage'
export default {
	// 机构人员列表
	name: 'organMember',
	props: {
		dialogVisible: {
			type: Boolean,
			default: false,
		},
		organId: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			loading: false,
			tableData: [],
			tableParams: {
				pageSize: 10,
				pageNum: 1,
			},
			total: 0,
		}
	},
	mounted() {
		this.getList()
	},
	methods: {
		handleClose() {
			this.$emit('update:dialogVisible', false)
		},
		handleSizeChange(pageSize) {
			this.tableParams.pageSize = pageSize
			this.doSearch()
		},
		handleCurrentChange(pageNum) {
			this.tableParams.pageNum = pageNum
			this.getList()
		},
		doSearch() {
			this.tableParams.pageNum = 1
			this.getList()
		},
		getList() {
			this.loading = true
			hksStaffPage({ ...this.tableParams, serviceId: this.organId })
				.then((res) => {
					this.tableData = res.data.records
					this.total = res.data.total
					this.loading = false
				})
				.catch(() => {
					this.tableData = []
					this.total = 0
					this.loading = false
				})
		},
	},
}
</script>

<style lang="scss" scoped>
.img {
	width: 40px;
	height: 40px;
	object-fit: cover;
}
</style>
